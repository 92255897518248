import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../layouts/DefaultLayout';
import ecIcon from '../assets/content-icons/indication-icons-hnscc.png';
import { Body, BodyStyle, DesktopOnly, EndoTitle, H3, H4, H6, HeaderH5Style, MobileOnly, Reference, Ul, PreviewTitleStyle } from '../util/typography';
import hero from '../assets/hero-banners/ec-image-desktop.png';
import PageIntroduction from '../components/PageIntroduction';
import Section from '../components/Section';
import Subsection from '../components/Subsection';
import * as colors from '../util/colors';
import Chart from '../components/Chart';
import mobileChart from '../assets/content-icons/ec-chart-mobile.png';
import desktopChart from '../assets/content-icons/ec-chart-desktop.png';
import desktopBaselineChart from '../assets/content-icons/ec-baseline-desktop.png';
import mobileBaselineChart from '../assets/content-icons/ec-baseline-mobile.png';
import ecWoman from '../assets/content-icons/ec-woman-photo.png';
import Table from '../components/Table';
import PrimaryCallout from '../components/PrimaryCallout';
import mobileEfficacyChart from '../assets/content-icons/ec-efficacy-mobile.png';
import desktopEfficacyChart from '../assets/content-icons/ec-efficacy-desktop.png';
import desktopPfsChart from '../assets/content-icons/ec-pfs-desktop.png';
import mobilePfsChart from '../assets/content-icons/ec-pfs-mobile.png';
import desktopOrrChart from '../assets/content-icons/ec-orr-desktop.png';
import mobileOrrChart from '../assets/content-icons/ec-orr-mobile.png';
import desktopDorChart from '../assets/content-icons/ec-dor-desktop.png';
import mobileDorChart from '../assets/content-icons/ec-dor-mobile.png';
import mobileUaChart from '../assets/content-icons/ec-os-mobile-chart.png';
import desktopUaChart from '../assets/content-icons/ec-os-final-analysis-km-curve-desktop_v06.png';
import mobileComAR from '../assets/content-icons/ec-common-ar-mobile.png';
import { respondTo } from '../util/breakpoints';
import SecondaryCallout from '../components/SecondaryCallout';
import RelatedContent from '../components/RelatedContent';
import { jobCodes } from '../util/data/jobCodes';

const pageMeta = {
    title: `Advanced Endometrial Carcinoma Clinical Trial Results`,
    keywords: `endometrial carcinoma clinical trial results, KEYNOTE-775/Study 309`,
    description: `Health care professionals can review clinical trial results in patients with certain types of advanced endometrial carcinoma.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/endometrial-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA + LENVIMA","alternateName":"pembrolizumab + lenvatinib","description":"KEYTRUDA, in combination with LENVIMA, is indicated for the treatment of patients with advanced endometrial carcinoma that is mismatch repair proficient (pMMR) as determined by an FDA-approved test or not microsatellite instability-high (MSI-H), who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/endometrial-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/endometrial-carcinoma/","@type":"MedicalWebPage","name":"Adverse reactions in KEYNOTE-775/Study 309","description":"The safety of KEYTRUDA + LENVIMA was investigated in KEYNOTE-775/Study 309 in patients treated with KEYTRUDA + LENVIMA (n=342) compared to doxorubicin or paclitaxel (n=325) at the protocol-specified interim analysis."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/endometrial-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE-775/Study 309","description":"KEYNOTE-775/Study 309: A pivotal (N=827), multicenter, open-label, randomized, active-controlled, head-to-head phase 3 trial of KEYTRUDA + LENVIMA vs doxorubicin or paclitaxel in patients with advanced endometrial carcinoma.1 Among the 827 patients, 697 had pMMR (using the VENTANA MMR RxDx Panel test) or not MSI-H tumors","trialDesign":"In KEYNOTE-775/Study 309: KEYTRUDA + LENVIMA demonstrated superiority in OS, PFS, and ORR vs doxorubicin or paclitaxel alone at protocol-specified interim analysis"}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/endometrial-carcinoma/","@type":"MedicalEntity","guideline":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) recommend pembrolizumab (KEYTRUDA) + lenvatinib (LENVIMA) (CATEGORY 1) as a biomarker-directed therapy useful in certain circumstances, after prior platinum-based therapy including neoadjuvant and adjuvant, for recurrent or metastatic endometrial carcinoma in patients whose tumors are pMMR.","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)"}`
    ]
}

const jobCode = jobCodes[2].jobCode;

const HeroImage = styled.img`
    max-width: 1032px;
    margin: 0 auto;
    display: block;
`;

const PageIntro = styled(PageIntroduction)`
    font-weight: 400;
    padding-bottom: 20px;
    ${respondTo.md`
        padding-bottom: 30px;
    `}
`;

const SectionIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    i {
        letter-spacing: 1.2px;
    }
`;

const Subtitle = styled.h5`
    ${HeaderH5Style};
    color: ${colors.brandGreen};
    line-height: 18px;
`;

const RccFootnote = styled(Reference)`
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
        width: 100%;
    }
    sup {
        display: flex;
        flex: 0 0 6px;
        justify-content: center;
    }
`;

const EfficacyTableRow = styled.div`
    display: flex;
    padding: 0;
    > * {
        padding: 20px 5px 20px 10px;
        flex: 1 1 90px;
        &:first-child {
            flex: 0 0 90px;
            font-weight: 700;
        }
    }
    ${respondTo.md`
        &:nth-child(2n + 1){
            background: ${colors.white};
        }
        > * {
            padding: 18px 30px;
            flex: 1 1 342px;
            &:first-child {
                flex: 0 0 342px;
            }
        }
    `}
    &:has([class*='__Reference']){
        background: ${colors.white};
        padding: 20px;
    }
    [class*='__Reference'] {
        flex-basis: 100%;
        padding: 0;
        font-weight: 400;
    }
`;

const DirTableRow = styled(EfficacyTableRow)`
    > * {
        flex: 1 1 114px;
        &:first-child {
            flex: 0 0 114px;
            font-weight: 400;
        }
        ${respondTo.md`
            flex: 1 1 280px;
            &:first-child {
                flex: 0 0 280px;
            }
        `}
    }
    p {
        font-size: 14px;
        ${respondTo.md`
            font-size: 16px;
        `}
    }
    &:has([class*='__Reference']){
        padding: 15px;
        ${respondTo.md`
            padding: 20px;
        `}
    }
    > * {
        &.full-width {
            flex: 1 1 100%;
            text-align: center;
        }
    }
    ${respondTo.md`
        > * {
            &.full-width {
                flex: 1 1 100%;
                font-weight: normal;
                padding: 20px;
            }
        }
    `}
`;

const EfficacyTableColumns = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 15px 5px;
    > * {
        text-align: center;
        flex: 1 0 85px;
    }
    ${respondTo.md`
        padding: 18px 30px;
        > * {
            flex: 1 0 280px;
        }
    `}
`;

const DirTableColumns = styled(EfficacyTableColumns)`
    > * {
        flex: 1 0 73px;
    }
    ${respondTo.md`
        > * {
            flex: 1 0 280px;
        }
    `}
`;

const NoTitleTable = styled(Table)`
    > * {
        text-align: center;
        &:nth-child(2n){
            background: ${colors.white};
        }
        > :first-child {
            flex: 0 0 115px;
            text-align: left;
            padding: 15px 5px 15px 10px;
        }
        ${respondTo.md`
            > :first-child {
                flex: 0 0 342px;
                text-align: left;
                padding: 18px 30px;
            }
        `}
    }
    &.mobile {
        [class*='__Body']{
            margin: auto 0;
        }
    }
`;

const RccTable = styled(Table)`
    h6{
        padding: 20px 10px;
        text-align: center;
        border-bottom: 1px solid ${colors.pebble};
        ${respondTo.md`
            padding: 30px;
        `}
    }
`;

const RccEfficacyIntro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

const CommonARColumn = styled.div`
    padding: 15px 30px;
    gap: 10px;
    display: flex;
    width: 100%;
    > * {
        flex: 1 0 50%;
        text-align: center;
    }
`;

const AnchorNavLinks = [
    {
        title: 'Study Design and Baseline Characteristics',
        href:'#study-design',
    },
    {
        title: 'Efficacy',
        href:'#efficacy',
    },
    {
        title: 'Adverse Reactions',
        href:'#adverse-reaction',
    },
    {
        title: 'NCCN Recommendation',
        href: '#nccn-recommendation'
    }
];


const EndoTable = styled(Table)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border: 1px solid ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.lightGrey};
    h6{
        text-align: center;
    }
      
    > *:first-child {
        padding: 20px 10px;
        background: ${colors.white};
        border-bottom: 1px solid ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.lightGrey};
        color: ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
        ${respondTo.md`
             padding: 30px;
        `}
     }

    [class*='__ShadedTableRow'] {
        background: ${colors.white};
    }

    [class*='__ShadedTableColumn'] > * {
        border-bottom: 1px solid ${colors.mist};
    }
    
    [class*='__ShadedTableColumn']:nth-child(1) > :nth-child(2n){
        background: ${colors.white};
    }

    [class*='__ShadedTableColumn']:nth-child(3) > :nth-child(${({noOfCells}) => noOfCells === 'odd' ? '2n + 1' : '2n'}){
        background: ${colors.white};
    }

    ${respondTo.md`
        [class*='__ShadedTableColumn']:nth-child(1) > :nth-child(2n){
            background: ${colors.white};
        }
        [class*='__ShadedTableColumn']:nth-child(3) > :nth-child(2n){
            background: ${colors.white};
        }
    `}

`;

const ShadedTableRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    .divider  {
        display: none;
    }
    > :nth-child(3){
        > :last-child {
            border-radius: 0 0 8px 8px;
        }
    }
    .last-element-hidden{
        border-radius: 0 0 8px 8px;
        border-bottom: 0;
    }
    .bottomBorder{
        border-bottom: 1px solid ${colors.mist};
    }
    > * {
        &.full-width {
            flex: 1 1 100%;
            font-weight: normal;
            padding: 18px 30px;
            text-align: center;
        }
    }
    ${respondTo.md`
        flex-direction: row;
        .divider  {
            display: block;
            background: white;
            width: 10px;
            height: 100%;
            border: 1px solid white;
            min-height: 55px;
        }
        > :nth-child(1){
            > :last-child {
                border-radius: 0 0 0px 8px;
            }
        }
        > :nth-child(3){
            > :last-child {
                border-radius: 0 0 8px 0px;
            }
        }
        .last-element-hidden{
            border-radius: 0;
            border-bottom: 1px solid ${colors.mist};
        }
    `}
`;

const ShadedTableColumn = styled.div`
    width: 100%;
    p {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 30px;
        padding-right: 30px;
    }
    ${respondTo.md`
        width: 50%; 
    `}
`;

const GreenTableHeader = styled(H6)`
    color: ${colors.brandGreen};
`;

const MulberryTableHeader = styled(H6)`
    color: ${colors.mulberry};
`;

const ShadedTableSingle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    ${BodyStyle};
    padding: 15px 10px;
    text-align: center;
    ${respondTo.md`
        padding: 18px 30px;
    `}
`;

const EndoLayout = styled(DefaultLayout)`
    [class*='__PageHeaderTitle']{
        ${EndoTitle};
    }
`;



const RCCEfficacyCallout = styled(PrimaryCallout)`
    [class*='PrimaryCallout__Title-']{
        font-weight: 400;
    }
    &:before {
        border-left: 5px solid ${colors.mulberry};
        background: linear-gradient(96deg, ${colors.brandGreen} 47.42%, ${colors.greenGradient} 100%);
    }
    &.noBorder {
        padding-left: 20px;
        ${respondTo.md`
            padding-left: 0px;
        `}
    }
    &.noBorder:before {
        content: none;
    }
`;

const BaselineCallout = styled(RCCEfficacyCallout)`
    padding: 40px 20px 0 40px;
    height: 288px;
    max-width: 100%;
    position: relative;
    .callout-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 220px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        [class*='__Body-']{
            line-height: 0;
        }
        img{
            width: 158px;
            height: 140px;
        }
        ${respondTo.md`
            flex-direction: row;
            padding: 0 0px 0 60px;
            gap: 40px;
            height: 140px;
            width: unset;
        `}
    }
    [class*='__Reference-']{
        position: absolute;
        bottom: -10px;
        transform: translateY(100%);
        right: 0;
    }
    ${respondTo.md`
        padding: 0 0px 0 60px;
        height: 140px;
    `}
`;

const OrrChart = styled(Chart)`
    [class*='__ChartTitle']{
        width: 100%;
    }
    ${respondTo.md`
        &.orr {
            img {
                width: 100%;
                margin: 0 auto;
                display: block;
            }
        }
    `}
`;

const UpdateAnalysisBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CommonARTableRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    > * :not(.divider) {
        border-bottom: 1px solid ${colors.mist};
    }
    .borderBottomNone {
        border-bottom: 0px;
    }
    > * {
        flex: 1 0 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
            flex: 0 0 310px;
        }
        &:first-child {
            justify-content: start;
            padding-left: 30px;
        }
    }
    &:last-child p{
        border-radius: 0 0 8px 8px;
    }
    &:has([class*='__Reference']){
        background: ${colors.white};
        padding: 20px;
    }
    [class*='__Reference'] {
        flex-basis: 100%;
        align-items: start;
        ${respondTo.md`
            padding: 0;
        `}
    }
    ${respondTo.md`
        flex-direction: row;
        .divider  {
            display: block;
            background: white;
            width: 10px;
            border: 1px solid white;
            min-height: 55px;
            flex: unset;
        }
    `}
`;

const CommonARTitle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
`;

const FDASection = styled(Section)`
    padding-bottom: 0;
    [class*='__SubsectionContent-']{
        padding-top: 0;
    }
    h6 {
        font-weight: 400;
    }
`;

const PdChart = styled(Chart)`
    [class*='__MobileOnly-']{
        display: flex;
        img{
            border-radius: 0 0 6px 6px;
            padding-top: 18px;
        }
    }

    [class*='__ChartTitle']{
        padding: 20px 10px;
    }

    [class*='__ChartChildren']{
        padding: 0;
    }
    [class*='__Reference']{
        padding: 0 15px 15px 15px;
    }
`;

const NccnSection = styled(Section)`

    [class*='__RccFootnote']{
        div {
            p {
                display: block;
            }
            sup {
                display: inline;
            }
        }
    }
`;

const NccnCallout = styled(SecondaryCallout)`
    ${PreviewTitleStyle};
    margin: 0 10px 10px 0;
    text-align: center;
    [class*='SecondaryCallout__Title-']{
        font-weight: 400;
    }
`;

const RccReference = styled(Subsection)`
    padding: 40px 20px 0 20px;
    word-break: break-word;
    ${respondTo.md`
        padding: 20px 30px 0 30px;
    `}
`;

const StudyDesignSection = styled(Section)`
    padding-bottom: 64px;
`;

const IndicationPageInto = styled(PageIntro)`
    gap: 0;
`;

const IndicationParagraph = styled(H4)`
    padding-bottom: 40px;
`;

const RCLinks = [
    {
        eyebrow: 'Safety',
        title: 'Monitor and help manage adverse reactions',
        to: '/safety'
    },
    {
        eyebrow: 'Dosing',
        title: 'Learn more about dosing and administration',
        to: '/dosing'
    },
];


const Endo = ({location}) => {
    return (
        <EndoLayout location={ location } name='KEYNOTE-775/Study 309 in Advanced Endometrial Carcinoma: Efficacy and Safety Results' icon={ecIcon} anchorNavLinks={AnchorNavLinks} pageMeta={pageMeta} jobCode={jobCode}>
            <DesktopOnly>
                <HeroImage src={hero} alt='' />
            </DesktopOnly>
            <IndicationPageInto>
                <H3><b>Indication</b></H3>
                <IndicationParagraph as="p">KEYTRUDA, in combination with LENVIMA, is indicated for the treatment of adult patients with advanced endometrial carcinoma that is mismatch repair proficient (pMMR) as determined by an FDA-approved test or not microsatellite instability-high (MSI-H), who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation.</IndicationParagraph>
                <H3><b>KEYNOTE-775/Study 309:</b> Pivotal phase 3 data that may help inform your approach to treating patients with certain types of advanced endometrial carcinoma</H3>    
            </IndicationPageInto>
            <StudyDesignSection title='Study Design and BASELINE Characteristics' bgColor='cloud' id='study-design'>
                <SectionIntro>
                    <Subtitle>FOCUS ON STUDY DESIGN</Subtitle>
                    <H4><b>KEYNOTE-775/Study 309:</b> A pivotal (N=827), multicenter, open-label, randomized, active-controlled, head-to-head phase 3 trial of <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel in patients with advanced endometrial carcinoma.<sup>1</sup> Among the 827 patients, 697 had pMMR (using the VENTANA MMR RxDx Panel test) or not MSI-H tumors</H4>
                </SectionIntro>
                <Subsection>
                    <Chart imgSrc={mobileChart} desktopImgSrc={desktopChart} noTitle showNote imgAlt='Study Design for KEYNOTE-775/Study 309 Clinical Trial'>
                    </Chart>
                    <RccFootnote>
                        <p><sup>a</sup>Treatment was permitted beyond RECIST v1.1–defined disease progression if the treating investigator considered the patient to be deriving clinical benefit and the treatment was tolerated.</p>
                        <p><sup>b</sup>According to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.</p>
                        <p>ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenously; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; BICR = blinded independent central review.</p>
                    </RccFootnote>
                </Subsection>
                <Subsection>
                    <Subtitle>FOCUS ON BASELINE CHARACTERISTICS</Subtitle>
                    <Chart imgSrc={mobileBaselineChart} desktopImgSrc={desktopBaselineChart} showNote imgAlt='697 Patients With Certain Types of Advanced Endometrial Carcinoma WereEnrolled in KEYNOTE-775/Study 309 Trial'>
                        <H6>KEYNOTE-775/Study 309 enrolled patients with advanced endometrial carcinoma that was pMMR or not MSI-H across histological subtypes and systemic treatment histories (N=697)</H6>
                    </Chart>
                    <BaselineCallout>
                        <div class='callout-row'>
                            <div>
                                <b>37% of patients</b><MobileOnly><br/></MobileOnly> received only prior neoadjuvant or adjuvant therapy.
                            </div>
                            <img src={ecWoman} alt=''/>
                        </div>
                        <Reference>Hypothetical Patient</Reference>
                    </BaselineCallout>
                </Subsection>
            </StudyDesignSection>
            <Section title='EFFICACY' id='efficacy'>
                <RccEfficacyIntro>
                    <H3>In KEYNOTE-775/Study 309: <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> demonstrated superiority in OS, PFS, and ORR vs doxorubicin or paclitaxel alone at protocol-specified interim analysis <br/></H3>
                    <SectionIntro>
                        <Subtitle>FOCUS ON OVERALL SURVIVAL</Subtitle>
                        <H4><b>Superior OS</b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel alone at protocol-specified interim analysis</H4>
                        <Ul marker={colors.charcoal}>
                            <li><b>HR<sup>c</sup>=0.68 (95% CI, 0.56–0.84); <i>P</i><sup>d</sup>=0.0001.</b></li>
                            <li>Events observed: 165/346 (48%) with KEYTRUDA + LENVIMA vs 203/351 (58%) with doxorubicin or paclitaxel.</li>
                            <li>OS and PFS were the major endpoints in KEYNOTE-775/Study 309.</li>
                        </Ul>
                        <RccFootnote>
                            <p><sup>c</sup>Based on the stratified Cox regression model.</p>
                            <p><sup>d</sup>Based on stratified log-rank test.</p>
                            <p>OS = overall survival; PFS = progression-free survival; ORR = objective response rate; HR = hazard ratio; CI = confidence interval.</p>
                        </RccFootnote>
                    </SectionIntro>
                </RccEfficacyIntro>
                <Subsection>
                    <Chart imgSrc={mobileEfficacyChart} desktopImgSrc={desktopEfficacyChart} showNote imgAlt='KEYNOTE-775/Study 309 Trial: OS at Protocol Specified Interim Analysis With KEYTRUDA® (pembrolizumab) + LENVIMA® (lenvatinib) vs. doxorubicin or paclitaxel Alone'>
                        <H6>Kaplan-Meier estimates for OS with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> (n=346) vs doxorubicin or paclitaxel (n=351) in the KEYNOTE-775/Study 309 trial (pMMR or not MSI-H)</H6>
                        <RccFootnote>
                            <p><sup>c</sup>Based on the stratified Cox regression model.</p>
                            <p><sup>d</sup>Based on stratified log-rank test.</p>
                        </RccFootnote>
                    </Chart>
                    <RCCEfficacyCallout bg='mist'>
                        <div>
                            Patients lived a <b>median of 5.4 months longer</b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel (17.4 vs 12.0 months, respectively).
                        </div>
                    </RCCEfficacyCallout>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON PROGRESSION-FREE SURVIVAL</Subtitle>
                        <H4><b>Superior PFS<sup>e</sup></b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel alone at protocol-specified interim analysis</H4>
                        <Ul marker={colors.charcoal}>
                            <li><b>HR<sup>c</sup>=0.60 (95% CI, 0.50–0.72); <i>P</i><sup>d</sup>&lt;0.0001.</b></li>
                            <li>Events observed: 247/346 (71%) with KEYTRUDA + LENVIMA vs 238/351 (68%) with doxorubicin or paclitaxel.</li>
                            <li>PFS and OS were the major endpoints in KEYNOTE-775/Study 309.</li>
                        </Ul>
                    </SectionIntro>
                    <Chart imgSrc={mobilePfsChart} desktopImgSrc={desktopPfsChart} showNote imgAlt='KEYNOTE-775/Study 309 Trial: PFS at Protocol Specified Interim Analysis With KEYTRUDA® (pembrolizumab) + LENVIMA® (lenvatinib) vs. doxorubicin or paclitaxel Alone'>
                        <H6>Kaplan-Meier estimates for PFS<sup>e</sup> with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> (n=346) vs doxorubicin or paclitaxel (n=351) in the KEYNOTE-775/Study 309 trial (pMMR or not MSI-H)</H6>
                        <RccFootnote>
                            <p><sup>c</sup>Based on the stratified Cox regression model.</p>
                            <p><sup>d</sup>Based on the stratified log-rank test.</p>
                            <p><sup>e</sup>Per independent radiology review.</p>
                        </RccFootnote>
                    </Chart>
                    <RCCEfficacyCallout bg='mist'>
                        <div>
                        Patients lived a <b>median of 2.8 months longer without disease progression</b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel (6.6 vs 3.8 months, respectively).
                        </div>
                    </RCCEfficacyCallout>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON OBJECTIVE RESPONSE RATE</Subtitle>
                        <H4><b>Superior ORR</b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel alone at protocol-specified interim analysis</H4>
                    </SectionIntro>
                    <OrrChart imgSrc={mobileOrrChart} desktopImgSrc={desktopOrrChart} showNote className='orr' imgAlt='The Objective Response Rates With KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) vs doxorubicin or paclitaxel Alone'>
                        <H6>ORR (additional endpoint): pMMR or not MSI-H</H6>
                        <RccFootnote>
                            <p><sup>e</sup>Per independent radiology review.</p>
                            <p><sup>f</sup>Response: Best objective response as confirmed CR or PR.</p>
                            <p><sup>g</sup>Based on Miettinen and Nurminen method stratified by ECOG PS, geographic region, and history of pelvic radiation.</p>
                            <p>CR = complete response; PR = partial response.</p>
                        </RccFootnote>
                    </OrrChart>
                    <RCCEfficacyCallout bg='mist'>
                        <div>
                            <b>2x greater ORR</b> with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel.
                        </div>
                    </RCCEfficacyCallout>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>DURATION OF RESPONSE</Subtitle>
                        <H4><b>LONGER MEDIAN DOR</b> observed with <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b> vs doxorubicin or paclitaxel alone at protocol-specified interim analysis</H4>
                    </SectionIntro>
                    <OrrChart imgSrc={mobileDorChart} desktopImgSrc={desktopDorChart} showNote imgAlt='Median Duration of Response Observed With KEYTRUDA® (pembrolizumab) + LENVIMA® (lenvatinib) vs. doxorubicin or paclitaxel Alone'>
                        <H6>DOR (additional endpoint): pMMR or not MSI-H</H6>
                        <RccFootnote>
                            DOR = duration of response.
                        </RccFootnote>
                    </OrrChart>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>UPDATED ANALYSIS</Subtitle>
                        <H4><b>OS</b> at protocol-specified final analysis<sup>2,3</sup></H4>
                        <UpdateAnalysisBody>
                            <Body><b>LIMITATION:</b> No formal statistical testing was planned for the protocol-specified final OS analysis and, therefore, no conclusions can be drawn.<sup>4</sup>
                            </Body>
                            <Ul marker={colors.charcoal}><li>In the chemotherapy arm, 10.0% of patients in the pMMR population received subsequent treatment with KEYTRUDA + LENVIMA.<sup>5</sup></li></Ul>
                        </UpdateAnalysisBody>
                    </SectionIntro>
                    <OrrChart imgSrc={mobileUaChart} desktopImgSrc={desktopUaChart} showNote imgAlt='Kaplan-Meier Estimates for OS at Protocol-specified Final Analysis in KEYNOTE-775/Study 309 Trial'>
                        <H6>Kaplan-Meier estimates for OS in KEYNOTE-775/Study 309 (pMMR or not MSI-H)<sup>2,4</sup></H6>
                        <Reference>
                            Makker, V. 2023, April 23. Lenvatinib plus pembrolizumab in previously treated advanced endometrial cancer: updated efficacy and safety from the randomized Phase III Study 309/KEYNOTE-775. 10.1200/JCO.22.02152 <span><i>Journal of Clinical Oncology.</i></span>
                        </Reference>
                    </OrrChart>
                    <Ul marker={colors.charcoal}>
                        <li><b>Median OS: 18.0 months</b> (95% CI, 14.9–20.5) with KEYTRUDA + LENVIMA vs <b>12.2 months</b> (95% CI, 11.0–14.1) with doxorubicin or paclitaxel alone<sup>2</sup></li>
                        <li><b>Median follow-up time: 14.7 months overall</b> (&gt;16 months of additional follow-up time from the interim analysis for OS; data cutoff date: March 1, 2022)<sup>2</sup></li>
                    </Ul>
                </Subsection>
            </Section>
            <Section bgColor='cloud' title='Adverse Reactions' id='adverse-reaction'>
                <SectionIntro>
                    <H3><b>Adverse reactions</b> in KEYNOTE-775/Study 309</H3>
                    <Body>The safety of KEYTRUDA + LENVIMA was investigated in KEYNOTE-775/Study 309 in patients treated with KEYTRUDA + LENVIMA (n=342) compared to doxorubicin or paclitaxel (n=325) at the protocol-specified interim analysis.</Body>
                    <Ul marker={colors.charcoal}>
                        <li>Median duration of study treatment was 7.2 months (range: 1 day–26.8 months).</li>
                    </Ul>
                </SectionIntro>
                <Subsection>
                    <DesktopOnly>
                        <NoTitleTable type='default'>
                            <EfficacyTableRow>
                                <Body></Body>
                                <EfficacyTableColumns>
                                    <Body><keytitle><b>KEYTRUDA</b></keytitle></Body>
                                    <Body><lentitle><b>LENVIMA</b></lentitle></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><b>Median duration of exposure (months)</b></Body>
                                <EfficacyTableColumns>
                                    <Body>6.8 (range: 1 day–25.8 months)</Body>
                                    <Body>6.7 (range: 1 day–26.8 months)</Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                        </NoTitleTable>
                    </DesktopOnly>
                    <MobileOnly>
                        <NoTitleTable type='default' className='mobile'>
                            <EfficacyTableRow>
                                <Body></Body>
                                <EfficacyTableColumns>
                                    <Body><b>Median duration of exposure (months)</b></Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><keytitle><b>KEYTRUDA</b></keytitle></Body>
                                <EfficacyTableColumns>
                                    <Body>6.8 (range: 1 day–25.8 months)</Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                            <EfficacyTableRow>
                                <Body><lentitle><b>LENVIMA</b></lentitle></Body>
                                <EfficacyTableColumns>
                                    <Body>6.7 (range: 1 day–26.8 months)</Body>
                                </EfficacyTableColumns>
                            </EfficacyTableRow>
                        </NoTitleTable>
                    </MobileOnly>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON FATAL AND SERIOUS ADVERSE REACTIONS</Subtitle>
                    </SectionIntro>
                    <EndoTable type='default' noOfCells='even'>
                        <H6>Fatal adverse reactions occurred in 4.7% of patients treated with <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle>, including 2 cases of pneumonia, and 1 case of the following:</H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Acute kidney injury</Body>
                                <Body>Acute myocardial infarction</Body>
                                <Body>Colitis</Body>
                                <Body>Decreased appetite</Body>
                                <Body>Intestinal perforation</Body>
                                <Body>Lower gastrointestinal hemorrhage</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                                <ShadedTableColumn>
                                <Body>Malignant gastrointestinal obstruction</Body>
                                <Body>Multiple organ dysfunction syndrome</Body>
                                <Body>Myelodysplastic syndrome</Body>
                                <Body>Pulmonary embolism</Body>
                                <Body className='last-element-hidden'>Right ventricular dysfunction</Body>
                                <DesktopOnly><Body></Body></DesktopOnly>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <RCCEfficacyCallout className='noBorder'>
                        <div>
                            <b>Serious adverse reactions occurred in 50% of patients receiving <b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle></b>.</b>
                        </div>
                    </RCCEfficacyCallout>
                    <EndoTable type='default' noOfCells='odd'>
                        <H6>Serious adverse reactions (≥3%) in patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> were: </H6>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Hypertension (4.4%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Urinary tract infection (3.2%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON DISCONTINUATION, INTERRUPTION, AND DOSE REDUCTION</Subtitle>
                    </SectionIntro>
                    <RccTable type='default'>
                        <H6>Discontinuation and interruption rates for <keytitle><b>KEYTRUDA</b></keytitle> and for <lentitle><b>LENVIMA</b></lentitle>, and dose reduction rate for <lentitle><b>LENVIMA</b></lentitle> due to adverse reactions in KEYNOTE-775/Study 309</H6>
                        <DirTableRow>
                            <Body></Body>
                            <DirTableColumns>
                                <Body><keytitle><b>KEYTRUDA</b></keytitle></Body>
                                <Body><lentitle><b>LENVIMA</b></lentitle></Body>
                            </DirTableColumns>
                        </DirTableRow>
                        <DirTableRow>
                            <Body>Discontinuation</Body>
                            <DirTableColumns>
                                <Body>15%</Body>
                                <Body>26%</Body>
                            </DirTableColumns>
                        </DirTableRow>
                        <DirTableRow>
                            <Body>Dose reduction</Body>
                            <DirTableColumns>
                                <Body>N/A</Body>
                                <Body>67%</Body>
                            </DirTableColumns>
                        </DirTableRow>
                        <DirTableRow>
                            <Body>Dose interruption</Body>
                            <DirTableColumns>
                                <Body>48%</Body>
                                <Body>58%</Body>
                            </DirTableColumns>
                        </DirTableRow>
                        <DirTableRow>
                            <Body className='full-width'>
                                No dose reduction for <keytitle><b>KEYTRUDA</b></keytitle> is recommended.
                            </Body>
                        </DirTableRow>
                        <DirTableRow>
                            <Reference>N/A = not applicable.</Reference>
                        </DirTableRow>
                    </RccTable>
                    <EndoTable type='keytruda'>
                        <GreenTableHeader>The most common (≥1%) adverse reaction leading to discontinuation of KEYTRUDA was:</GreenTableHeader>
                        <ShadedTableSingle>
                            Increased alanine aminotransferase (1.2%)
                        </ShadedTableSingle>
                    </EndoTable>
                    <EndoTable type='lenvima' noOfCells='odd'>
                        <MulberryTableHeader>The most common (≥1%) adverse reactions leading to discontinuation of LENVIMA were:</MulberryTableHeader>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Hypertension (2%)</Body>
                                <Body>Asthenia (1.8%)</Body>
                                <Body>Diarrhea (1.2%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Decreased appetite (1.2%)</Body>
                                <Body>Proteinuria (1.2%)</Body>
                                <Body>Vomiting (1.2%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <EndoTable type='keytruda'>
                        <GreenTableHeader>The most common (≥3%) adverse reactions leading to interruption of KEYTRUDA were:</GreenTableHeader>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Diarrhea (8%)</Body>
                                <Body>Increased alanine aminotransferase (4.4%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Increased aspartate aminotransferase (3.8%)</Body>
                                <Body>Hypertension (3.5%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <EndoTable type='lenvima' noOfCells='odd'>
                        <MulberryTableHeader>The most common (≥2%) adverse reactions leading to interruption of LENVIMA were:</MulberryTableHeader>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Hypertension (11%)</Body>
                                <Body>Diarrhea (11%)</Body>
                                <Body>Proteinuria (6%)</Body>
                                <Body>Decreased appetite (5%)</Body>
                                <Body>Vomiting (5%)</Body>
                                <Body>Increased alanine aminotransferase (3.5%)</Body>
                                <Body>Fatigue (3.5%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Nausea (3.5%)</Body>
                                <Body>Abdominal pain (2.9%)</Body>
                                <Body>Weight decreased (2.6%)</Body>
                                <Body>Urinary tract infection (2.6%)</Body>
                                <Body>Increased aspartate aminotransferase (2.3%)</Body>
                                <Body>Asthenia (2.3%)</Body>
                                <Body>Palmar-plantar erythrodysesthesia (2%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                    </EndoTable>
                    <EndoTable type='lenvima' noOfCells='even'>
                        <MulberryTableHeader>The most common (≥5%) adverse reactions resulting in dose reduction of LENVIMA were:</MulberryTableHeader>
                        <ShadedTableRow>
                            <ShadedTableColumn>
                                <Body>Hypertension (18%)</Body>
                                <Body>Diarrhea (11%)</Body>
                                <Body>Palmar-plantar erythrodysesthesia syndrome (9%)</Body>
                                <Body>Proteinuria (7%)</Body>
                            </ShadedTableColumn>
                            <div className='divider'/>
                            <ShadedTableColumn>
                                <Body>Fatigue (7%)</Body>
                                <Body>Decreased appetite (6%)</Body>
                                <Body>Asthenia (5%)</Body>
                                <Body>Weight decreased (5%)</Body>
                            </ShadedTableColumn>
                        </ShadedTableRow>
                        <ShadedTableRow>
                            <Body className='full-width'>No dose reduction for <b><keytitle>KEYTRUDA</keytitle></b> is recommended.</Body>
                        </ShadedTableRow>
                    </EndoTable>
                </Subsection>
                <Subsection>
                    <SectionIntro>
                        <Subtitle>FOCUS ON MOST COMMON ADVERSE REACTIONS</Subtitle>
                    </SectionIntro>
                    <DesktopOnly>
                        <EndoTable type='default'>
                            <H6>Adverse reactions occurring in ≥20% of patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> in KEYNOTE-775/Study 309</H6>
                            <CommonARTableRow>
                                <Body><b>Adverse Reaction</b></Body>
                                <div className='divider'/>
                                <CommonARTitle>
                                    <Body><b><keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> (n=342)</b></Body>
                                    <CommonARColumn>
                                        <Body><b>All Grades<sup>h</sup> (%)</b></Body>
                                        <Body><b>Grade 3–4 (%)</b></Body>
                                    </CommonARColumn>
                                </CommonARTitle>
                                <div className='divider'/>
                                <CommonARTitle>
                                    <Body><b>Doxorubicin or Paclitaxel (n=325)</b></Body>
                                    <CommonARColumn>
                                        <Body><b>All Grades<sup>h</sup> (%)</b></Body>
                                        <Body><b>Grade 3–4 (%)</b></Body>
                                    </CommonARColumn>
                                </CommonARTitle>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Hypothyroidism<sup>i</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>67</Body>
                                    <Body>0.9</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>0.9</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Hypertension<sup>j</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>67</Body>
                                    <Body>39</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>6</Body>
                                    <Body>2.5</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Fatigue<sup>k</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>58</Body>
                                    <Body>11</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>54</Body>
                                    <Body>6</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Diarrhea<sup>l</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>55</Body>
                                    <Body>8</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>20</Body>
                                    <Body>2.8</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Musculoskeletal disorders<sup>m</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>53</Body>
                                    <Body>5</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>27</Body>
                                    <Body>0.6</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Nausea</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>49</Body>
                                    <Body>2.9</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>47</Body>
                                    <Body>1.5</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Decreased appetite<sup>n</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>44</Body>
                                    <Body>7</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>21</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Vomiting</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>37</Body>
                                    <Body>2.3</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>21</Body>
                                    <Body>2.2</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Stomatitis<sup>o</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>35</Body>
                                    <Body>2.6</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>26</Body>
                                    <Body>1.2</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Abdominal pain<sup>p</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>34</Body>
                                    <Body>2.6</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>21</Body>
                                    <Body>1.2</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Weight loss</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>34</Body>
                                    <Body>10</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>6</Body>
                                    <Body>0.3</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Urinary tract infection<sup>q</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>31</Body>
                                    <Body>5</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>13</Body>
                                    <Body>1.2</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Proteinuria<sup>r</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>29</Body>
                                    <Body>6</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>3.4</Body>
                                    <Body>0.3</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Constipation</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>27</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>25</Body>
                                    <Body>0.6</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Headache</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>26</Body>
                                    <Body>0.6</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>9</Body>
                                    <Body>0.3</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Hemorrhagic events<sup>s</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>25</Body>
                                    <Body>2.6</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>15</Body>
                                    <Body>0.9</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Palmar-plantar erythrodysesthesia<sup>t</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>23</Body>
                                    <Body>2.9</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>0.9</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Dysphonia</Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>22</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>0.6</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <Body>Rash<sup>u</sup></Body>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>20</Body>
                                    <Body>2.3</Body>
                                </CommonARColumn>
                                <div className='divider'/>
                                <CommonARColumn>
                                    <Body>4.9</Body>
                                    <Body>0</Body>
                                </CommonARColumn>
                            </CommonARTableRow>
                            <CommonARTableRow>
                                <RccFootnote className='borderBottomNone'>
                                    <p><sup>h</sup>Graded per NCI-CTCAE v4.03.</p>
                                    <p><sup>i</sup>Includes hypothyroidism, blood thyroid stimulating hormone increased, thyroiditis, primary hypothyroidism, secondary hypothyroidism.</p>
                                    <p><sup>j</sup>Includes hypertension, blood pressure increased, hypertensive crisis, secondary hypertension, blood pressure abnormal, hypertensive encephalopathy, blood pressure fluctuation.</p>
                                    <p><sup>k</sup>Includes fatigue, asthenia, malaise, lethargy.</p>
                                    <p><sup>l</sup>Includes diarrhea, gastroenteritis.</p>
                                    <p><sup>m</sup>Includes arthralgia, myalgia, back pain, pain in extremity, bone pain, neck pain, musculoskeletal pain, arthritis, musculoskeletal chest pain, musculoskeletal stiffness, non-cardiac chest pain, pain in jaw.</p>
                                    <p><sup>n</sup>Includes decreased appetite, early satiety.</p>
                                    <p><sup>o</sup>Includes stomatitis, mucosal inflammation, oropharyngeal pain, aphthous ulcer, mouth ulceration, cheilitis, oral mucosal erythema, tongue ulceration.</p>
                                    <p><sup>p</sup>Includes abdominal pain, abdominal pain upper, abdominal pain lower, abdominal discomfort, gastrointestinal pain, abdominal tenderness, epigastric discomfort.</p>
                                    <p><sup>q</sup>Includes urinary tract infection, cystitis, pyelonephritis.</p>
                                    <p><sup>r</sup>Includes proteinuria, protein urine present, hemoglobinuria.</p>
                                    <p><sup>s</sup>Includes epistaxis, vaginal hemorrhage, hematuria, gingival bleeding, metrorrhagia, rectal hemorrhage, contusion, hematochezia, cerebral hemorrhage, conjunctival hemorrhage, gastrointestinal hemorrhage, hemoptysis, hemorrhage urinary tract, lower gastrointestinal hemorrhage, mouth hemorrhage, petechiae, uterine hemorrhage, anal hemorrhage, blood blister, eye hemorrhage, hematoma, hemorrhage intracranial, hemorrhagic stroke, injection site hemorrhage, melena, purpura, stoma site hemorrhage, upper gastrointestinal hemorrhage, wound hemorrhage, blood urine present, coital bleeding, ecchymosis, hematemesis, hemorrhage subcutaneous, hepatic hematoma, injection site bruising, intestinal hemorrhage, laryngeal hemorrhage, pulmonary hemorrhage, subdural hematoma, umbilical hemorrhage, vessel puncture site bruise.</p>
                                    <p><sup>t</sup>Includes palmar-plantar erythrodysesthesia syndrome, palmar erythema, plantar erythema, skin reaction.</p>
                                    <p><sup>u</sup>Includes rash, rash maculo-papular, rash pruritic, rash erythematous, rash macular, rash pustular, rash papular, rash vesicular, application site rash.</p>
                                    <p>NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events.</p>
                                </RccFootnote>
                            </CommonARTableRow>
                        </EndoTable>
                    </DesktopOnly>
                    <MobileOnly>
                        <PdChart imgSrc={mobileComAR} showNote imgAlt=''>
                            <H6>Adverse reactions occurring in ≥20% of patients receiving <keytitle>KEYTRUDA</keytitle> + <lentitle>LENVIMA</lentitle> in KEYNOTE-775/Study 309</H6>
                            <RccFootnote>
                                    <p><sup>h</sup>Graded per NCI-CTCAE v4.03.</p>
                                    <p><sup>i</sup>Includes hypothyroidism, blood thyroid stimulating hormone increased, thyroiditis, primary hypothyroidism, secondary hypothyroidism.</p>
                                    <p><sup>j</sup>Includes hypertension, blood pressure increased, hypertensive crisis, secondary hypertension, blood pressure abnormal, hypertensive encephalopathy, blood pressure fluctuation.</p>
                                    <p><sup>k</sup>Includes fatigue, asthenia, malaise, lethargy.</p>
                                    <p><sup>l</sup>Includes diarrhea, gastroenteritis.</p>
                                    <p><sup>m</sup>Includes arthralgia, myalgia, back pain, pain in extremity, bone pain, neck pain, musculoskeletal pain, arthritis, musculoskeletal chest pain, musculoskeletal stiffness, non-cardiac chest pain, pain in jaw.</p>
                                    <p><sup>n</sup>Includes decreased appetite, early satiety.</p>
                                    <p><sup>o</sup>Includes stomatitis, mucosal inflammation, oropharyngeal pain, aphthous ulcer, mouth ulceration, cheilitis, oral mucosal erythema, tongue ulceration.</p>
                                    <p><sup>p</sup>Includes abdominal pain, abdominal pain upper, abdominal pain lower, abdominal discomfort, gastrointestinal pain, abdominal tenderness, epigastric discomfort.</p>
                                    <p><sup>q</sup>Includes urinary tract infection, cystitis, pyelonephritis.</p>
                                    <p><sup>r</sup>Includes proteinuria, protein urine present, hemoglobinuria.</p>
                                    <p><sup>s</sup>Includes epistaxis, vaginal hemorrhage, hematuria, gingival bleeding, metrorrhagia, rectal hemorrhage, contusion, hematochezia, cerebral hemorrhage, conjunctival hemorrhage, gastrointestinal hemorrhage, hemoptysis, hemorrhage urinary tract, lower gastrointestinal hemorrhage, mouth hemorrhage, petechiae, uterine hemorrhage, anal hemorrhage, blood blister, eye hemorrhage, hematoma, hemorrhage intracranial, hemorrhagic stroke, injection site hemorrhage, melena, purpura, stoma site hemorrhage, upper gastrointestinal hemorrhage, wound hemorrhage, blood urine present, coital bleeding, ecchymosis, hematemesis, hemorrhage subcutaneous, hepatic hematoma, injection site bruising, intestinal hemorrhage, laryngeal hemorrhage, pulmonary hemorrhage, subdural hematoma, umbilical hemorrhage, vessel puncture site bruise.</p>
                                    <p><sup>t</sup>Includes palmar-plantar erythrodysesthesia syndrome, palmar erythema, plantar erythema, skin reaction.</p>
                                    <p><sup>u</sup>Includes rash, rash maculo-papular, rash pruritic, rash erythematous, rash macular, rash pustular, rash papular, rash vesicular, application site rash.</p>
                                    <p>NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events.</p>
                                </RccFootnote>
                        </PdChart>
                    </MobileOnly>
                </Subsection>
            </Section>
            <FDASection>
                <SectionIntro>
                    <H6><b>FDA-APPROVED INDICATION</b><br/>KEYTRUDA, in combination with LENVIMA, is indicated for the treatment of adult patients with advanced endometrial carcinoma that is mismatch repair proficient (pMMR) as determined by an FDA-approved test or not microsatellite instability-high (MSI&#8209;H), who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation.</H6>
                </SectionIntro>
                <Subsection></Subsection>
            </FDASection>
            <NccnSection title='NCCN RECOMMENDATION' id='nccn-recommendation'>
                <SectionIntro>
                    <NccnCallout noIcon>
                        <p><b>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)</b> recommend pembrolizumab (<keytitle><b>KEYTRUDA</b></keytitle>) + lenvatinib (<lentitle><b>LENVIMA</b></lentitle>) (<b>CATEGORY 1</b>) as a biomarker-directed therapy useful in certain circumstances, after prior platinum-based therapy including neoadjuvant and adjuvant, for recurrent or metastatic endometrial carcinoma in patients whose tumors are pMMR.<sup>6</sup></p>
                    </NccnCallout>
                </SectionIntro>
                <Subsection>
                    <RccFootnote>
                        <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
                        <div><p>Category 1: Based upon high-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup>6</sup></p></div>
                        <div><p>Useful in certain circumstances = Other interventions that may be used for selected patient populations (defined with recommendation).<sup>6</sup></p></div>
                        <p>NCCN = National Comprehensive Cancer Network.</p>
                    </RccFootnote>
                </Subsection>
            </NccnSection>
            <RelatedContent title='Learn more about adverse reaction management and dosing' links={RCLinks}/>
            <Section noIntro>
                <RccReference>
                    <Reference>
                        <b>References: 1.</b> Makker V, Colombo N, Casado Herráez A, et al. Lenvatinib plus pembrolizumab for advanced endometrial cancer. <i>N Engl J Med.</i> 2022;386(5):437–448. doi:10.1056/NEJMoa2108330 <b>2.</b> Makker V, Colombo N, Herráez AC, et al. Lenvatinib plus pembrolizumab in previously treated advanced endometrial cancer: updated efficacy and safety from the randomized phase III Study 309/KEYNOTE-775. <i>J Clin Oncol.</i> 2023:JCO2202152. doi:10.1200/JCO.22.02152 <b>3.</b> Makker V, Colombo N, Herráez AC, et al. Lenvatinib plus pembrolizumab in previously treated advanced endometrial cancer: updated efficacy and safety from the randomized phase III Study 309/KEYNOTE-775. Protocol 775-08/E7080-G000-309. <i>J Clin Oncol.</i> 2023:JCO2202152. Accessed September 5, 2024. https://ascopubs.org/doi/suppl/10.1200/JCO.22.02152/suppl_file/protocol_JCO.22.02152.pdf <b>4.</b> Data available on request from Merck Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-KLE-01281. <b>5.</b> Makker V, Colombo N, Herráez AC, et al. Lenvatinib plus pembrolizumab in previously treated advanced endometrial cancer: updated efficacy and safety from the randomized phase III Study 309/KEYNOTE-775. Data supplement. <i>J Clin Oncol.</i> 2023:JCO2202152. Accessed September 5, 2024. https://ascopubs.org/doi/suppl/10.1200/JCO.22.02152/suppl_file/DS_JCO.22.02152.pdf <b>6.</b> Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Uterine Neoplasms V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed March 12, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org. 
                    </Reference>
                </RccReference>
            </Section>
        </EndoLayout>
    )
};

export default Endo;
